<template>
  <v-container>
    <h2 class="h2Style">
      <img
        class="oee"
        style="vertical-align: middle"
        src="../assets/wrenchBlack.svg"
      />
      Rate Cards
    </h2>
    <v-row style="margin-top: 40px">
      <v-col cols="3">
        <v-autocomplete
          :items="this.agenciesList"
          item-text="name"
          item-value="agencyId"
          label="Select Agency"
          dense
          outlined
          @input="seletedAgency"
        ></v-autocomplete>
      </v-col>
      <v-col cols="5">
        <v-btn depressed color="primary" @click="getAgencyRateCard">
          View
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" dark @click="getAllRateCards">
          Download All
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-dialog v-model="addRateCardDialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Add/Update
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add new rateCard for Agency</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row v-for="(eachItem, key) in addRateCardItems" :key="key">
                  <v-autocomplete
                    v-if="eachItem === 'agencyId'"
                    :items="agenciesList"
                    item-text="name"
                    item-value="agencyId"
                    label="Select Agency"
                    dense
                    outlined
                    v-model="addRateCard[eachItem]"
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    v-model="addRateCard[eachItem]"
                    color="purple darken-2"
                    :label="eachItem"
                    type="number"
                    required
                  ></v-text-field>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="(addRateCardDialog = false), (addRateCard = {})"
              >
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveRateCardToDB">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Field</th>
            <th class="text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in rateCardData" :key="key">
            <td v-if="key != '_id' && key != '__v'">{{ key }}</td>
            <td v-if="key != '_id' && key != '__v'">{{ value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import axios from "axios";
import constants from "../constants";
let token = sessionStorage.getItem("jwt");
export default {
  name: "rateCard",
  data: () => ({
    agenciesList: [],
    selectedAgencyObjectId: "",
    rateCardData: {},
    addRateCardDialog: false,
    updateRateCardDialog: false,
    selectedagencyId: "",
    selectedagencyName: "",
    addRateCard: {},
    addRateCardItems: [
      "agencyId",
      "Standard Banner 0kb_100kb",
      "Standard Banner 101kb_150kb",
      "Standard Banner 151kb_300kb",
      "Rich Media 0kb_299kb",
      "Rich Media 300kb_1mb",
      "Rich Media 1mb_5mb",
      "Rich Media 5mb_10mb",
      "In-Stream Video Tracking",
      "In-stream Audio & Non-Interactive Video",
      "In-stream Interactive Video",
      "Impressions and Click Tracking",
      "Click Tracking Only",
      "Display Dynamic Creative",
      "Video Dynamic Creative",
      "Attribution Suite Expert",
      "Search Analytics",
      "Raw Data Feed",
      "Verification - Basic",
      "Verification - Advanced",
    ],
  }),
  methods: {
    getAllRateCards() {
      axios
        .get(`${constants.backend_url}/allRateCards`)
        .then((response) => {
          console.log("All Rate Cards", response.data);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          this.uploading = "false";
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "AllRateCards.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    },
    saveRateCardToDB() {
      console.log("clicked the save");
      let config = {
        method: "post",
        url: `${constants.backend_url}/rateCard`,
        headers: {
          token: `"${token}"`,
          "Content-Type": "application/json",
        },
        data: this.addRateCard,
      };

      axios(config)
        .then((response) => {
          this.agenciesList = response.data.data;
          this.addRateCardDialog = false;
          this.addRateCard = {};
          this.getAgencies();
          this.selectedAgencyObjectId= "";
          this.rateCardData= {};
          alert("successfully added Rate-Card", response.data.data);
        })
        .catch((error) => {
          console.log("error adding ratecard", error.message);
          alert("error adding Rate-Card", error);
        });
    },
    getAgencies() {
      axios
        .get(`${constants.backend_url}/agencies`)
        .then((response) => {
          this.agenciesList = response.data.data;
        })
        .catch((error) => {
          console.log("error fetching agencies list", error.message);
          alert("error fetching agencies list", error.message);
        });
    },
    seletedAgency(event) {
      console.log("selected agency", event);
      this.selectedAgencyObjectId = event;
      for (let i = 0; i < this.agenciesList.length; i++) {
        if (this.agenciesList[i].agencyId === this.selectedAgencyObjectId) {
          this.selectedagencyId = this.agenciesList[i].agencyId;
          this.selectedagencyName = this.agenciesList[i].name;
        }
      }
    },
    getAgencyRateCard() {
      let token = sessionStorage.getItem("jwt");
      axios
        .get(
          `${constants.backend_url}/rateCard?agencyId=${this.selectedagencyId}`,
          { headers: { token: `"${token}"` } }
        )
        .then((response) => {
          this.rateCardData = response.data.data;
        })
        .catch((error) => {
          console.log("error fetching agencies list", error.message);
          alert("error fetching the agency", error.message);
        });
    },
  },
  mounted() {
    this.getAgencies();
  },
};
</script>

<style>
</style>